<template>
    <div class="main">
        <div class="main-width pt-5">
            <div class="alert alert-success text-center" role="alert" v-if="resultSuccess">
                {{ $t("registration.success_message") }}
            </div>
            <div class="alert alert-warning text-center" role="alert" v-else>
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActivateRegistration",
    data() {
        return {
            resultSuccess: false,
            errorMessage: null
        }
    },
    mounted() {
        this.onShow();
    },
    methods: {
        onShow() {
            if (this.$route.params.id) {
                this.activateAccount(this.$route.params.id);
            }
        },
        activateAccount(id) {
            this.$store.dispatch("REGISTRATION", {id: id})
                .then(() => {
                    this.resultSuccess = true;
                    this.gotoProfile();
                })
                .catch(e => {
                    e.message && (this.errorMessage = e.message);
                })
                ;
        },
        gotoProfile() {
            this.$store.dispatch('IS_AUTH')
                .then(() => {
                    this.$store.dispatch("GET_USER")
                        .then(() => {
                            let router = this.$router;
                            setTimeout(function() {
                                router.push({ name: "profile" });
                            }, 3000);
                        })
                        ;
                })
                ;
        }
    }
}
</script>